import { version } from './version';

export const environment = {
  BASE_URL: 'https://dteguaruja.giss.com.br/PlatformaDTe',
  production: false,
  product: 'dte',
  client: 'eicon',
  i18n: 'pt_br',
  version: version,
  i18nPath: './assets/i18n/',
  assetsPath: './assets/',
  powerbi: '3518701',
  schema: 'pbi_dte_guaruja',
  groupId: '872904c7-b9d4-471e-8e09-116211078de6',
  analiseGeral: '95327d56-3d57-4c5b-bfbd-51f034a06140',
  analiseUsuarios: 'e2341211-06f5-4af8-acdf-254658b42777',
  analiseComunicados: '59e449e9-937d-4a74-9fb4-595a1c2aa600',
  analiseProcurador: 'af1f0c95-ccba-45e6-bcf2-1c24238b0384',
  analiseEventos: '40a0a33c-2aa8-4079-bcf1-40db56973020'
};
